<template>
  <div
    class="container size-mobile bg-element-white min-height-second paket"
    style="font-family: Montserrat;padding-left:15px !important;"
  >
    
    <v-dialog />
    <alert ref="c"/>
    <alert-error ref="ae" />
    <form
      id="edit-form"
      class="form-horizontal d-flex flex-column mt-1"
      method="POST"
      @submit.prevent="store()"
      enctype="multipart/form-data"
      style="min-height: 88vh;"
    >
    <div class="alert alert-primary mt-4" role="alert" @click="cara()" style="border: none !important;border-radius: 0px;">
      <i class="fa fa-info-circle pr-2"></i><span style="font-size:12px;">Cara Input Paket <b>(Klik Disini)</b></span>
    </div>
      <div class="mb-1">
        <legend class="legend-input">Jenis Paket</legend>
        <div class="d-flex mt-2">
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="jenis"
              value="barang"
              v-model="data.jenis_paket"
            />
            <label class="form-check-label">Barang</label>
          </div>
          <div class="form-check ml-4">
            <input
              class="form-check-input"
              type="radio"
              name="jenis"
              value="dokumen"
              v-model="data.jenis_paket"
            />
            <label class="form-check-label">Dokumen</label>
          </div>
        </div>
      </div>
      <fieldset class>
        <legend class="legend-input">No. Resi</legend>
        <input
          type="text"
          name="modal-input-resi"
          id="modal-input-resi"
          class="form-control"
          autocomplete="off"
          v-model="data.no_resi"
          required="required"
        />
      </fieldset>
      <fieldset class>
        <legend class="legend-input">Nama Pengirim</legend>
        <input
          type="text"
          placeholder="Contoh: ari rahmatul"
          name="modal-input-pengirim"
          id="modal-input-pengirim"
          class="form-control"
          autocomplete="off"
          v-model="data.pengirim"
          required="required"
        />
      </fieldset>
      <fieldset class>
        <legend class="legend-input">Nama Penerima</legend>
        <input
          type="text"
          placeholder="Contoh: huda ( Bagian Keuangan )"
          name="modal-input-penerima"
          id="modal-input-penerima"
          class="form-control"
          autocomplete="off"
          v-model="data.penerima"
          required="required"
        />
      </fieldset> 
      <!-- <fieldset class>
        <legend class="legend-input">Ditujukan Untuk</legend>
        <input
          type="text"
          name="modal-input-resi"
          id="modal-input-resi"
          class="form-control"
          autocomplete="off"
          v-model="data.bagian_penerima"
          required="required"
        />
      </fieldset> -->
      <button
        class="btn-blue mt-3"
        style="width:100%"
        :class="{'disable': isSubmit}"
        type="submit"
      >
        <b>Simpan</b>
      </button>
    </form>
    
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import Api from "../../Api";
import alert from "../../components/Alert.vue";
import alertError from "../../components/AlertError.vue";

export default {
  name: "addPaket",
  components: {
    alertError,
    alert
  },
  data() {
    return {
      startDate: new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-"),
      isLoading: true,
      data: {
        jenis_paket: 'barang',
        user_id: '',
        pengirim: '',
        penerima: '',
        no_resi: '',
        workplace_id: '',
        // bagian_penerima: '',
      },
      total: 0,
      dataModal: [],
      isModalVisible: false,
      updatedContent: "",
      token: "",
      myData: "",
      isSubmit: false,
      dataFiltered: 0,
      img: require('../../assets/img/upload.png'),
    };
  },

  async mounted() {
    this.token = JSON.parse(localStorage.getItem("token"));
    this.myData = JSON.parse(localStorage.getItem("cred"));
    // console.log(JSON.parse(localStorage.getItem('cred')));
  },

  methods: {
    async store() {
      try {
        this.data.user_id = this.myData.id;
        this.data.workplace_id = this.myData.id_tempat_kerja;
        console.log(this.data)
        // this.isLoading = true
        await Api.post("satpam/paket", this.data, {
          headers: { Authorization: "Bearer " + this.token }
        }).then((response) => {
          console.log(response)
          this.$router.go(-1)
          // this.$router.push(this.$route.query.redirect || '/paket')
          // console.log(response);
            // this.done = true;
        });
      } catch (error) {
        console.log(error);
      }
    },
    cara(){
      this.$refs.c.showAlert('Cara Input Paket','1. <b>Form Nama Pengirim</b> diisi <b>Nama Pengirim</b> yang ada di Paket<br>2. <b>Form Nama Penerima</b> diisi <b>Nama Penerima</b> yang ada di Paket,dan disertai nama divisi penerima<br>3. <b>No Resi</b> diisi <b>No Resi</b> yang ada di Paket<br>')
    }
  }
};
</script> 